import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
    textWhite: {
        color: 'white',
        textTransform: 'uppercase',
    },
}));

const Tenxgenomics = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <div className={className} {...rest}>
            <SectionHeader
                title={<span className={classes.textWhite}>Chromium Controller</span>}
                titleVariant="h2"
                subtitle={<span className={classes.textWhite}>Single cell resolution to maximize biological insight </span>}
                ctaGroup={[
                    <Link to ='/Genomic/?pid=genomicrange'>
                    <Button variant="outlined" color="secondary" size="large">

                        Explore all 10X Genomics

                    </Button>
                    </Link>
                ]}
                disableGutter
                data-aos="fade-up"
            />
        </div>
    );
};
Tenxgenomics.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default Tenxgenomics;
