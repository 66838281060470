import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { colors, Divider } from '@material-ui/core';
import { IconAlternate } from 'components/molecules';
import { Section, Parallax } from 'components/organisms';

import Medsys from './components/Medsys'

import { Illumina, Covaris, Fluidigm, Tenxgenomics, } from './components'




const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    fullHeight: {
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    disablePaddingTop: {
        paddingTop: 0,
    },
    scrollIcon: {
        background: 'transparent !important',
        border: `2px solid ${colors.amber[500]}`,
        cursor: 'pointer',
    },
    scrollTopIcon: {
        margin: '0 auto',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(12),
        },
    },
    madcapSection: {
        backgroundColor: '#0000008a',
    },
    adidasSection: {
        backgroundColor: '#003c0580',
    },
    larqSection: {
        backgroundColor: '#2d402f80',
    },
    nikeSection: {
        backgroundColor: '#5f3f3f80',
    },
}));

const Featuredrange = () => {
    const classes = useStyles();

    const scrollTo = id => {
        setTimeout(() => {
            const element = document.querySelector(`#${id}`);
            if (!element) {
                return;
            }

            window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
        });
    };
    return (
        <div className={classes.root}>
            {/* <div className={classes.fullHeight}> */}

            {/* <Section className={classes.disablePaddingTop}> */}
        
            {/* </Section> */}

            <Parallax
                backgroundImage="https://onwhicar.sirv.com/premas/main/illumina/novaseqx.jpg"
                id="Covaris"
            >
                <div className={clsx(classes.fullHeight, classes.nikeSection)}>
                    <Section>
                    <Illumina />
                    </Section>
                    <IconAlternate
                        shape="circle"
                        fontIconClass="fas fa-chevron-down"
                        color="#1597BB"
                        size="small"
                        className={classes.scrollIcon}
                        onClick={() => scrollTo('Medsys')}
                        data-aos="fade-up"
                    />
                </div>
            </Parallax>



            <Parallax
                backgroundImage="https://onwhicar.sirv.com/premas/main/10x/thumb%20controller%20png.png"
                id="chromiumcontroller"
            >
                <div className={clsx(classes.fullHeight, classes.madcapSection)}>
                    <Section>
                        <Tenxgenomics />
                    </Section>
                    <IconAlternate
                        shape="circle"
                        fontIconClass="fas fa-chevron-down"
                        color={colors.blueGrey}
                        size="small"
                        className={classes.scrollIcon}
                        onClick={() => scrollTo('Novaseq')}
                        data-aos="fade-up"
                    />
                </div>
            </Parallax>
            {/* <div className={classes.fullHeight} id="agency-process">
        <Section>
          <Process />
        </Section>
        <IconAlternate
          shape="circle"
          fontIconClass="fas fa-chevron-down"
          color={colors.blueGrey}
          size="small"
          className={classes.scrollIcon}
          onClick={() => scrollTo('agency-adidas')}
          data-aos="fade-up"
        />
      </div> */}
            <Parallax
                backgroundImage="https://onwhicar.sirv.com/premas/main/NovaSeq.png"
                id="Novaseq"
            >
                <div className={clsx(classes.fullHeight, classes.adidasSection)}>
                    <Section>
                        <Illumina />
                    </Section>
                    <IconAlternate
                        shape="circle"
                        fontIconClass="fas fa-chevron-down"
                        color={colors.blueGrey}
                        size="small"
                        className={classes.scrollIcon}
                        onClick={() => scrollTo('Juno')}
                        data-aos="fade-up"
                    />
                </div>
            </Parallax>

            <Parallax
                backgroundImage="https://onwhicar.sirv.com/premas/main/BioXp%203250.png"
                id="Juno"
            >
                <div className={clsx(classes.fullHeight, classes.larqSection)}>
                    <Section>
                        <Medsys/>
                    </Section>
                    <IconAlternate
                        shape="circle"
                        fontIconClass="fas fa-chevron-down"
                        color={colors.blueGrey}
                        size="small"
                        className={classes.scrollIcon}
                        onClick={() => scrollTo('Covaris')}
                        data-aos="fade-up"
                    />
                </div>
            </Parallax>
            <Parallax
                backgroundImage="https://onwhicar.sirv.com/premas/Twist_Bioscience_Official_Logo.png"
                id="Covaris"
            >
                <div className={clsx(classes.fullHeight, classes.nikeSection)}>
                    <Section>
                        <Covaris />
                    </Section>
                    <IconAlternate
                        shape="circle"
                        fontIconClass="fas fa-chevron-down"
                        color="#1597BB"
                        size="small"
                        className={classes.scrollIcon}
                        onClick={() => scrollTo('Medsys')}
                        data-aos="fade-up"
                    />
                </div>
            </Parallax>
            <Parallax
                backgroundImage="https://onwhicar.sirv.com/premas/main/FLUIDIGM_CyTOF_XT.png"
                id="Fluidigm"
            >
                <div className={clsx(classes.fullHeight, classes.larqSection)}>
                    <Section>
                        <Fluidigm />
                    </Section>
                    <IconAlternate
                        shape="circle"
                        fontIconClass="fas fa-chevron-down"
                        color={colors.blueGrey}
                        size="small"
                        className={classes.scrollIcon}
                        onClick={() => scrollTo('agency-nike')}
                        data-aos="fade-up"
                    />
                </div>
            </Parallax>


            <Divider />

            {/* <IconAlternate
        shape="circle"
        fontIconClass="fas fa-chevron-up"
        color={colors.blueGrey}
        size="small"
        className={clsx(classes.scrollIcon, classes.scrollTopIcon)}
        onClick={() => scrollTo('agency-madcap')}
        data-aos="fade-up"
      /> */}
            <Divider />
        </div>
    );
};

export default Featuredrange;
