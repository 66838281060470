import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';


const useStyles = makeStyles(() => ({
    textWhite: {
        color: 'white',
        textTransform: 'uppercase',
    },
}));

const Covaris = props => {
    const { className, ...rest } = props;
    const classes = useStyles();

    return (
        <div className={className} {...rest}>
            <SectionHeader
                title={<span className={classes.textWhite}>TWIST BIOSCIENCE</span>}
                titleVariant="h2"
                subtitle={<span className={classes.textWhite}>BROAD, DEEP SEQUENCING FOR BIGGER DISCOVERIES..</span>}
                ctaGroup={[
                    <Link to ='/Genomic/?pid=twist'>
                    <Button href='/enterprise/' variant="outlined" color="secondary" size="large">
                        Explore all Twist products
                    </Button>
                    </Link>
                ]}
                disableGutter
                data-aos="fade-up"
            />
        </div>
    );
};

Covaris.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
};

export default Covaris;
